





























































































































































































































































































































































@import '~variables'

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none
  .zero-width
    width 0

.notification-icon
  font-size 16px
.pointer
  cursor pointer
.table-control
  margin-right 20px
